var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container--fluid grid-list-md"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-medium text-body-1 primary--text text--darken-1"},[_vm._v(_vm._s(_vm.$t("journalEntry.journalEntryList")))]),(_vm.accessRight.includes('create'))?_c('CommonAdd',{attrs:{"name":_vm.$t('journalEntry.journalEntry'),"pathName":"addJournalEntry"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[(_vm.listSkeleton)?_c('ListSkeleton'):(_vm.journalEntryList.data)?_c('v-card-text',{staticClass:"text-h5 pa-0"},[_c('v-data-table',{staticClass:"custom-table mb-0 ma-2 row-pointer",staticStyle:{"word-break":"break-word"},attrs:{"dense":true,"headers":_vm.headers,"items":_vm.journalEntryList.data,"items-per-page":_vm.limit,"page":_vm.page,"server-items-length":_vm.journalEntryList.meta.total,"options":_vm.pagination,"item-class":"py-4","footer-props":{
              'items-per-page-options': [10, 20, 30, 40, 50],
            },"search":_vm.search,"sort-by":_vm.sortByValue,"sort-desc":_vm.sortDescValue,"must-sort":""},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.pagination=$event},"dblclick:row":_vm.redirectOnEditPage,"update:sortBy":function($event){_vm.sortByValue=$event},"update:sort-by":function($event){_vm.sortByValue=$event},"update:sortDesc":function($event){_vm.sortDescValue=$event},"update:sort-desc":function($event){_vm.sortDescValue=$event}},scopedSlots:_vm._u([{key:"item.date_create",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date_create && (_vm.profileById.date_format ? new Date(item.date_create).toISOString().substring(0, 10) : _vm.formatDateDDMMYYYY( new Date(item.date_create) .toISOString() .substring(0, 10) ))))])]}},{key:"item.status",fn:function(props){return [_c('v-chip',{attrs:{"color":_vm.getColor(props.item.status),"small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.statusList.filter(function (x) { return x.id == props.item.status; })[0].name)+" ")])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"text-body-2 primary--text mb-2"},[_c('v-row',{attrs:{"dense":true}},[_c('v-col',{staticClass:"text-end d-flex",attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"dense":true,"hide-details":"auto","items":_vm.statusList,"item-text":"name","item-value":"id","flat":"","return-object":"","single-line":"","label":_vm.$t('quotation.field.status')},on:{"input":_vm.buildUrl},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{staticClass:"text-end d-flex",attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"full-width":false,"dense":true,"hide-details":"auto","append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":""},on:{"input":_vm.buildUrl},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-end d-flex",attrs:{"cols":"3"}},[_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.journal_entry_date,"transition":"scale-transition","offset-y":"","nudge-left":40,"min-width":"auto"},on:{"update:returnValue":function($event){_vm.journal_entry_date=$event},"update:return-value":function($event){_vm.journal_entry_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"me-2",attrs:{"hide-details":"auto","dense":"","single-line":"","label":_vm.$t('order.from'),"clearable":""},on:{"click:clear":function($event){_vm.journal_entry_date = ''},"input":_vm.buildUrl},model:{value:(_vm.orderDateFormat),callback:function ($$v) {_vm.orderDateFormat=$$v},expression:"orderDateFormat"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{on:{"input":_vm.buildUrl,"change":function($event){return _vm.$refs.startDateMenu.save(_vm.journal_entry_date)}},model:{value:(_vm.journal_entry_date),callback:function ($$v) {_vm.journal_entry_date=$$v},expression:"journal_entry_date"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){_vm.$refs.startDateMenu.save(_vm.todayDate())}}},[_vm._v(_vm._s(_vm.$t("route.today")))])],1)],1),_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.journal_entry_validate_date,"transition":"scale-transition","offset-y":"","nudge-left":160,"min-width":"auto"},on:{"update:returnValue":function($event){_vm.journal_entry_validate_date=$event},"update:return-value":function($event){_vm.journal_entry_validate_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"auto","dense":"","single-line":"","label":_vm.$t('order.to'),"clearable":""},on:{"click:clear":function($event){_vm.journal_entry_validate_date = ''},"input":_vm.buildUrl},model:{value:(_vm.orderValideDateFormat),callback:function ($$v) {_vm.orderValideDateFormat=$$v},expression:"orderValideDateFormat"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.journal_entry_date ? _vm.journal_entry_date : ''},on:{"input":_vm.buildUrl,"change":function($event){return _vm.$refs.endDateMenu.save(_vm.journal_entry_validate_date)}},model:{value:(_vm.journal_entry_validate_date),callback:function ($$v) {_vm.journal_entry_validate_date=$$v},expression:"journal_entry_validate_date"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){_vm.$refs.endDateMenu.save(_vm.todayDate())}}},[_vm._v(_vm._s(_vm.$t("route.today")))])],1)],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"footer.prepend",fn:function(){return [_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12"}},[_c('span',{staticClass:"pl-3 pt-2"},[_vm._v(_vm._s(_vm.$t("common.numberOfTotalRows"))+" "+_vm._s(_vm.journalEntryList.meta.total))])])],1)]},proxy:true},{key:"footer.page-text",fn:function(){return [_c('v-row',{staticClass:"d-flex"},[_c('v-col',{staticClass:"mx-auto mr-16",attrs:{"cols":"4"}},[_c('v-container',{staticClass:"w-100"},[_c('v-pagination',{attrs:{"total-visible":5,"length":_vm.journalEntryList.meta.last_page},on:{"input":_vm.buildUrl},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)]},proxy:true}],null,true)})],1):_c('v-card-text',{staticClass:"text-caption text-center"},[_vm._v(" "+_vm._s(_vm.$t("common.noDataFound")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }