<template>
  <div>
    <div v-if="this.$route.name === 'JournalEntry'">
      <JournalEntry />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import JournalEntry from "@/views/companyApp/journalEntry/JournalEntry.vue";

export default {
  name: "JournalEntryParent",
  components: {
    JournalEntry,
  },
};
</script>
